import React, { Component } from 'react';
import './Info.css';

interface Props {
  info: any
}
class Info extends Component<Props, {}> {
  render() {
    const info = this.props.info;

    return (
      <div className="Recipe-Info">
        <div className="Recipe-Section-Header">
          {/* <img src="/static/svg/icon-information.svg" alt="Info Icon" /> */}
          <h2>Info</h2>
        </div>
        <ul>
          <li><img src="/static/svg/icon-clock.svg" alt="Clock Icon" />Prep Time: <span 
            // @ts-ignore
            property="prepTime" content={`PT ${info.prep_time_minutes} M`}>{info.prep_time_minutes} Minutes</span></li>
          <li><img src="/static/svg/icon-clock.svg" alt="Clock Icon" />Cook Time: <span 
            // @ts-ignore
            property="cookTime" content={`PT ${info.cook_time_minutes} M`}>{info.cook_time_minutes} Minutes</span></li>
          <li><img src="/static/svg/icon-clock.svg" alt="Clock Icon" />Chill Time: <span>{info.chill_time_minutes} Minutes</span></li>
          <li><img src="/static/svg/icon-grid.svg" alt="Clock Icon" />Total Servings: <span property="recipeYield">{info.total_servings}</span></li>
          {info.calories !== 0 &&
            // @ts-ignore
            <li rel="nutrition" typeof="NutritionInformation"><img src="/static/svg/icon-graph-bar.svg" alt="Clock Icon" />Calories: <span property="calories">{info.calories}</span></li>
          }
        </ul>
      </div>
    );
  }
}

export default Info;
