import React, { Component, ErrorInfo } from 'react';
import { ENDPOINTS, DOCUMENT_TITLE, IMAGE_URL } from './../../Config/Config';
import './Recipe.css';

import Tracker from './../Tracker/Tracker';
import MainLoader from './../MainLoader/MainLoader';

import Info from './Info';
import Ingredients from './Ingredients';
import Instructions from './Instructions';

interface Props {
  match: any;
}
interface State {
  recipe: any;
  loaded: boolean;
  img: string;
  hasError: boolean;
  display_img: boolean;
}

class Recipe extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      recipe: {},
      loaded: false,
      img: '',
      hasError: false,
      display_img: true,
    };

    this.loadRecipe = this.loadRecipe.bind(this);
    this.handleImageError = this.handleImageError.bind(this);
  }

  loadRecipe() {
    fetch(ENDPOINTS.getRecipe + this.props.match.params.id)
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          recipe: data,
          loaded: true,
          img: this.getImageURL(data.id),
        })
      );
  }

  getImageURL(name: string) {
    return IMAGE_URL + name + '.jpg';
  }

  handleImageError() {
    if (localStorage.getItem('broken-images') === 'none') {
      this.setState({ display_img: false });
    } else {
      const NUM = Math.floor(Math.random() * 10);
      this.setState({ img: this.getImageURL('NotFound_' + NUM) });
    }
  }

  componentDidMount() {
    this.loadRecipe();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ loaded: false, display_img: true });
      this.loadRecipe();
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    console.log(error.message + info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="Recipe">
          <h1>Unable to render</h1>
        </div>
      );
    }

    if (this.state.loaded) {
      const recipe = this.state.recipe;
      document.title = `${recipe.name} - ${DOCUMENT_TITLE}`;

      var date;
      if (recipe.date_published != null) {
        date = new Date(recipe.date_published).toDateString();
      }

      var image;
      if (this.state.display_img) {
        image = (
          <div className="Recipe-Header-Image">
            <img
              property="image"
              src={this.state.img}
              alt={recipe.name}
              onError={this.handleImageError}
            />
          </div>
        );
      }

      return (
        <div className="Recipe" vocab="http://schema.org/" typeof="Recipe">
          <div className="Recipe-Header">
            <div className="Recipe-Header-Title">
              <h2 property="name">{recipe.name}</h2>
              <h3 property="description">{recipe.description}</h3>
              <h4>
                By: <span property="author">{recipe.author}</span>
              </h4>
              <h4>
                On:{' '}
                <span 
                  // @ts-ignore
                  property="datePublished" content={recipe.date_published} >
                  {date}
                </span>
              </h4>
            </div>
            {image}
          </div>
          <div className="Recipe-Sections">
            <div className="Recipe-List">
              <Info info={recipe.info} />
              <Ingredients ingredients={recipe.ingredients} />
            </div>
            <div className="Recipe-Main">
              <Instructions instructions={recipe.instructions} />
            </div>
          </div>
        </div>
      );
    } else {
      return <MainLoader />;
    }
  }
}

export default Tracker(Recipe);
