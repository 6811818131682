import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import './Callback.css';

interface Props {
  auth: any;
  location: any;
}
interface State {
  authenticated: boolean;
  error: boolean;
  message: string;
}

class Callback extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      authenticated: false,
      error: false,
      message: '',
    };

    this.handleAuthentication = this.handleAuthentication.bind(this);
  }

  handleAuthentication(hash: string) {
    if (/access_token|id_token|error/.test(hash)) {
      this.props.auth.auth0.parseHash(
        { hash: hash },
        (err: any, authResult: any) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.props.auth.setSession(authResult);
            this.setState({ authenticated: true });
          } else if (err) {
            this.setState({
              error: true,
              message: JSON.stringify(err),
            });
          }
        }
      );
    } else {
      this.setState({
        error: true,
        message: 'Authentication token not found',
      });
    }
  }

  componentDidMount() {
    this.handleAuthentication(this.props.location.hash);
  }

  render() {
    if (this.state.authenticated) {
      // Authentication Succesfull return to page
      let Uri = this.props.auth.fetchRedirectUri();
      return <Redirect to={Uri} />;
    } else if (this.state.error) {
      return <div className="Callback">Error: {this.state.message}</div>;
    } else {
      return (
        <div className="Callback">
          <div className="Callback-Loader">
            <DotLoader size={150} color={'#0FBBEC'} loading={true} />
          </div>
        </div>
      );
    }
  }
}

export default Callback;
