import * as Yup from 'yup';
import moment from 'moment';

export const DEFAULT_RECIPE = {
    name: 'New Recipe',
    author: 'Blissfully Cooking',
    date_published: moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z',
    info: {
      calories: 0,
      chill_time_minutes: 0,
      cook_time_minutes: 0,
      prep_time_minutes: 0,
      total_servings: 1,
    },
    ingredients: [
      {
        name: '',
        amount: 0,
        whole: 0,
        numerator: 0,
        denominator: 0,
        measurement: ''
      }
    ],
    instructions: {
      steps : [
        {
          id: 1,
          text: ''
        }
      ]
    }
  };

export const RECIPE_SCHEMA = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    author: Yup.string()
      .required('Required'),
    date_published: Yup.string()
      .required('Required'),
    info: Yup.object({
      calories: Yup.number()
        .required('Required')
        .integer('Must be an integer'),
      chill_time_minutes: Yup.number()
        .required('Required')
        .integer('Must be an integer'),
      cook_time_minutes: Yup.number()
        .required('Required')
        .integer('Must be an integer'),
      prep_time_minutes: Yup.number()
        .required('Required')
        .integer('Must be an integer'),
      total_servings: Yup.number()
        .required('Required')
        .integer('Must be an integer')
        .positive('Must be a positive number'),
    }),
    ingredients: Yup.array().of(
      Yup.object({
        name: Yup.string()
          .required('Required'),
        amount: Yup.number()
          .required('Required'),
        whole: Yup.number(),
        numerator: Yup.number(),
        denominator: Yup.number(),
        measurement: Yup.string()
      }),
    ),
    instructions: Yup.object({
      steps: Yup.array().of(
        Yup.object({
          id: Yup.number(),
          text: Yup.string()
            .required('Required'),
        }),
      ),
    }),
  });