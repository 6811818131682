import React, { Component } from 'react';
import { Field, ErrorMessage } from 'formik';
import './Info.css';

class Info extends Component {
  render() {
    return (
      <div className="edit-info">
        <h4>Info</h4>
        <div>
          Calories: 
          <Field name="info.calories" type="number" />
          <a href="https://www.verywellfit.com/recipe-nutrition-analyzer-4157076" target="_blank" rel="noopener noreferrer">Calculator</a>
          <span> (per serving)</span>
          <ErrorMessage name="info.calories" component="span" className="error-msg" />
        </div>
        <div>
          Chill Time: <Field name="info.chill_time_minutes" type="number" /> minutes
          <ErrorMessage name="info.chill_time_minutes" component="span" className="error-msg" />
        </div>
        <div>
          Cook Time: <Field name="info.cook_time_minutes" type="number" /> minutes
          <ErrorMessage name="info.cook_time_minutes" component="span" className="error-msg" />
        </div>
        <div>
          Prep Time: <Field name="info.prep_time_minutes" type="number" /> minutes
          <ErrorMessage name="info.prep_time_minutes" component="span" className="error-msg" />
        </div>
        <div>
          Total Servings: <Field name="info.total_servings" type="number" />
          <ErrorMessage name="info.total_servings" component="span" className="error-msg" />
        </div>
      </div>
    );
  }
}

export default Info;
