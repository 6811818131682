import React, { Component, ErrorInfo } from 'react';
import { DOCUMENT_TITLE } from './../../Config/Config';
import './Home.css';
import Tracker from './../Tracker/Tracker';
import RecipeIndex from './RecipeIndex';

interface Props {}
interface State {
  hasError: boolean;
}

class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    console.log(error.message + info);
  }

  render() {
    document.title = DOCUMENT_TITLE;

    if (this.state.hasError) {
      return (
        <div className="Main">
          <h1>Unable to render</h1>
        </div>
      );
    }

    return <RecipeIndex />;
  }
}

export default Tracker(Home);
