import React, { Component, ErrorInfo } from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import Auth from './Auth/Auth';

import Home from './components/Home/Home';
import Nav from './components/Nav/Nav';
import Header from './components/Header/Header';
import Recipes from './components/Recipes/Recipes';
import Recipe from './components/Recipe/Recipe';
import Edit from './components/Edit/Edit';
import Callback from './components/Callback/Callback';
import Footer from './components/Footer/Footer';

interface Props {}
interface State {
  hasError: boolean;
}

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    console.log(error.message + info);
  }

  render() {
    const auth = new Auth();

    if (this.state.hasError) {
      return (
        <h1>
          There was a problem rendering the app. Please review the console for
          more details.
        </h1>
      );
    }

    return (
      <BrowserRouter>
        <div className="App">
          <Nav />
          <Header />
          <div className="Container">
            <Route path="" component={Recipes} />
            <Route path="/" exact component={Home} />
            <Route path="/recipe/:id" component={Recipe} />
            <Route
              path="/edit/:id"
              render={(props) => <Edit auth={auth} {...props} />}
            />
            <Route
              path="/callback"
              render={(props) => <Callback auth={auth} {...props} />}
            />
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
