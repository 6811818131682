import React, { Component, } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-58316013-2', { 
  testMode: process.env.NODE_ENV === 'test'
});

const Tracker = (WrappedComponent: any, options = {}) => {
  const trackPage = (page: string) => {
    //To Do: Check for blank before passing
    //ReactGA.set({ ...options, });
    ReactGA.pageview(page);
  };

  interface Props {
    location: any,
    auth: any
  }
  // eslint-disable-next-line
  const HOC = class extends Component<Props, {}> {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps: Props) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default Tracker;