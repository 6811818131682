import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './Nav.css';

class Nav extends Component {
  render() {
      return (
        <nav className="nav">
          <ul>
            <li className="nav-active"><Link to="/">Home</Link></li>
            <li><a href="http://blog.blissfullycooking.com">Blog</a></li>
            <li><a href="http://blog.blissfullycooking.com/archive">Archive</a></li>
          </ul>
        </nav>
      );
  }
}

export default Nav;
