import React, { Component } from 'react';
import { Field, ErrorMessage } from 'formik';
import './Main.css';

class Main extends Component {
  render() {
    return (
      <div className="edit-main">
        <h4>Main</h4>
        <div>
          <span>Name: </span>
          <Field name="name" />
          <ErrorMessage name="name" component="span" className="error-msg" />
        </div>
        <div>
          <span>Description: </span><br />
          <Field name="description" component="textarea" />
          <ErrorMessage name="description" component="span" className="error-msg" />
        </div>
        <div>
          <span>Author: </span>
          <Field name="author" placeholder="Blissfully Cooking"/>
          <ErrorMessage name="author" component="span" className="error-msg" />
        </div>
        <div>
          <span>Published Date: </span>
          <Field name="date_published" />
          <ErrorMessage name="date_published" component="span" className="error-msg" />
        </div>
        <div>
          <span>Category: </span>
          <Field name="recipe_category" placeholder="Dessert" />
          <ErrorMessage name="recipe_category" component="span" className="error-msg" />
        </div>
        <div>
          <span>Cuisine: </span> 
          <Field name="recipe_cuisine" placeholder="American" />
          <ErrorMessage name="recipe_cuisine" component="span" className="error-msg" />
        </div>
        <div>
          <span>Type: </span>
          <Field name="recipe_type" placeholder="Cookie" />
          <ErrorMessage name="recipe_type" component="span" className="error-msg" />
        </div>
        <div>
          <span>Tags: </span>
          <Field name="tags" />
          <ErrorMessage name="tags" component="span" className="error-msg" />
        </div>
      </div>
    );
  }
}

export default Main;
