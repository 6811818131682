import React, { Component } from 'react';
import { ENDPOINTS } from './../../Config/Config';
import './RecipeIndex.css';

import MainLoader from './../MainLoader/MainLoader';
import CategoryList from './../CategoryList/CategoryList';

interface Props { }

interface State {
    recipes: any,
    categories: any,
    loading: boolean
}

class RecipeIndex extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
    
        this.state = {
          recipes: [],
          categories: [],
          loading: true,
        };

        this.loadRecipes = this.loadRecipes.bind(this);
        this.calculateCategories = this.calculateCategories.bind(this);
    }

    calculateCategories(){
        // Pull out the categories from the filtered recipes
        const recipes = this.state.recipes;
        let categories = [],
            hash: any = {},
            length = recipes.length;
    
        // Loop through each item and add to hash - deduplicating
        for (let i=0; i<length; ++i) {
          hash[recipes[i].recipe_category as string] = recipes[i].recipe_category;
        }
    
        // Create array from hash
        for (let cntr in hash) {
          categories.push(hash[cntr]);
        }
    
        this.setState({categories: categories.sort() });
    }
    
    loadRecipes(){
        fetch(ENDPOINTS.getRecipes)
        .then(response => response.json())
        .then(data => this.setState({ recipes: data, loading: false }, this.calculateCategories));
    }

    componentDidMount() {
        this.loadRecipes();
    }

    render() {
        if(this.state.loading){
            return (
                <MainLoader />
            );
        }else{
            return (
                <div className="Main">
                    <div>
                        <h1>
                            Blissfully Cooking Cookbook
                        </h1>
                        <p>Choose a recipe to get started.</p>
                    </div>
                    <div className="MainList">
                        {this.state.categories.map((category: string) =>
                            <CategoryList key={category} category={category} recipes={this.state.recipes.filter((recipe: { recipe_category: string; }) => recipe.recipe_category === category)} />
                        )}
                    </div>
                </div>
            );
        }
    }
}

export default RecipeIndex;