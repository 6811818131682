import React, { Component, ErrorInfo } from 'react';
import { ENDPOINTS, DOCUMENT_TITLE } from './../../Config/Config';
import './Edit.css';

import Tracker from './../Tracker/Tracker';
import MainLoader from './../MainLoader/MainLoader';
import { Formik, Form } from 'formik';
import { RECIPE_SCHEMA, DEFAULT_RECIPE } from './RecipeSchema';
import { Redirect } from 'react-router-dom';

import Main from './Main';
import Info from './Info';
import Ingredients from './Ingredients';
import Instructions from './Instructions';

interface Props {
  auth: any,
  location: any,
  match: any
}
interface State {
  ready: boolean,
  recipe: any,
  redirect: boolean,
  updated: boolean,
  hasError: boolean
}

class Edit extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      ready: false,
      recipe: DEFAULT_RECIPE,
      redirect: false,
      updated: false,
      hasError: false,
    };

    this.authenticate = this.authenticate.bind(this);
    this.loadRecipe = this.loadRecipe.bind(this);
    this.newRecipe = this.newRecipe.bind(this);
    this.updateRecipe = this.updateRecipe.bind(this);
  }

  authenticate() {
    this.props.auth.authenticate(this.props.location.pathname);
  }

  loadRecipe() {
    if (this.props.match.params.id !== '0') {
      fetch(ENDPOINTS.getRecipe + this.props.match.params.id)
        .then((response) => response.json())
        .then((data) =>
          this.setState({ recipe: this.transformRecipeLoad(data), ready: true })
        );
    } else {
      // Add small sleep to give time for state to update
      setTimeout(
        () => {
          this.newRecipe();
        },
        100
      );
    }
  }

  transformRecipeUpdate(data: any) {
    // Create new instance to not modify original
    let recipe = JSON.parse(JSON.stringify(data));

    recipe.ingredients.forEach((item: any) => {
      if (item.whole !== undefined && item.whole !== 0) {
        if (item.denominator !== 0) {
          item.numerator =
            item.whole * item.denominator + parseInt(item.numerator);
        } else {
          item.numerator = item.whole;
          item.denominator = 1;
        }
      }
      delete item.whole;
    });

    // Update ID to match index
    recipe.instructions.steps.forEach((step: any, index: number) => {
      if (step.id !== index + 1) {
        step.id = index + 1;
      }
    });

    return recipe;
  }

  transformRecipeLoad(recipe: any) {
    recipe.ingredients.forEach((item: any) => {
      if (item.numerator !== 0 && item.denominator !== 0) {
        item.whole = Math.floor(item.numerator / item.denominator);
        item.numerator = item.numerator % item.denominator;
      }
    });

    return recipe;
  }

  newRecipe() {
    this.setState({ ready: true, recipe: DEFAULT_RECIPE });
  }

  updateRecipe(recipe: any, setSubmitting: any) {
    let url = `${ENDPOINTS.updateRecipe}`;

    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${this.props.auth.getAccessToken()}`,
      },
      body: JSON.stringify(this.transformRecipeUpdate(recipe)),
    })
      .then((response) => response.json())
      .then((data) => {
        if (this.props.match.params.id === '0') {
          // Created Recipe - need redirect
          this.setState({
            recipe: this.transformRecipeLoad(data),
            redirect: true,
          });
        } else {
          // Updated Recipe
          this.setState({
            recipe: this.transformRecipeLoad(data),
            updated: true,
          });
          setSubmitting(false);
          setTimeout(
            () => {
              this.setState({ updated: false });
            },
            1000
          );
        }
      });
  }

  componentDidMount() {
    this.loadRecipe();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.setState({ ready: false, redirect: false }, () => this.loadRecipe());
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    console.log(error.message + info);
  }

  render() {
    document.title = `Edit - ${DOCUMENT_TITLE}`;

    if (this.state.hasError) {
      return (
        <div className="Edit">
          <h1>Unable to render</h1>
        </div>
      );
    }

    if (this.state.ready && this.props.auth.isAuthenticated()) {
      if (this.state.redirect) {
        return (
          <Redirect
            to={`/edit/${this.state.recipe.id}/${this.state.recipe.name
              .split(' ')
              .join('_')}`}
          />
        );
      }

      document.title = `Edit - ${this.state.recipe.name} - ${DOCUMENT_TITLE}`;
      return (
        <div className="Edit">
          <h1>Editing recipes</h1>
          <Formik
            initialValues={this.state.recipe}
            validationSchema={RECIPE_SCHEMA}
            onSubmit={(values, { setSubmitting }) => {
              this.updateRecipe(values, setSubmitting);
            }}
          >
            {({ values, isSubmitting, setFieldValue }) => (
              <Form>
                <div className="edit-header">
                  <Main />
                  <Info />
                </div>
                <div className="edit-sections">
                  <Ingredients ingredients={values.ingredients} />
                  <Instructions
                    steps={values.instructions.steps}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <button type="submit" disabled={isSubmitting}>
                  {this.props.match.params.id === '0' ? 'Create' : 'Update'}
                </button>
                <span>{this.state.updated ? ' Updated Recipe!' : ''}</span>
              </Form>
            )}
          </Formik>
        </div>
      );
    } else if (this.props.auth.isAuthenticated()) {
      return <MainLoader />;
    } else {
      return (
        <div className="Edit">
          <h1>Login Required</h1>
          <button onClick={this.authenticate}>Login</button>
        </div>
      );
    }
  }
}

export default Tracker(Edit);
