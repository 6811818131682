import React, { Component } from 'react';
import { Field, ErrorMessage } from 'formik';
import Draggable from 'react-draggable';
import './Instruction.css';

interface Props {
  index: number,
  arrayHelpers: any,
  setFieldValue: any
}
interface State {
  InitialIndex: number;
  controlledPosition: {
    x: number,
    y: number
  }
}

class Instruction extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      InitialIndex: this.props.index,
      controlledPosition: {
        x: 0,
        y: 0,
      },
    };

    this.handleDrag = this.handleDrag.bind(this);
    this.handleStop = this.handleStop.bind(this);
  }

  handleDrag(e: any, position: {x: number, y: number}) {
    const { x, y } = position;
    this.setState({ controlledPosition: { x, y } });
  }

  handleStop() {
    const index = this.props.index;
    const newIndex = index + this.state.controlledPosition.y / 25;
    this.props.arrayHelpers.move(index, newIndex);

    this.setState({ controlledPosition: { x: 0, y: 0 } });
  }

  render() {
    var index = this.props.index;
    var arrayHelpers = this.props.arrayHelpers;

    return (
      <Draggable
        axis="y"
        handle=".id"
        defaultPosition={{ x: 0, y: 0 }}
        bounds=".instructions"
        grid={[25, 25]}
        scale={1}
        position={{ x: 0, y: 0 }}
        onDrag={this.handleDrag}
        onStop={this.handleStop}
      >
        <div className="instruction">
          <div className="id">
            <span>{index + 1}</span>
          </div>
          <Field
            name={`instructions.steps[${index}].text`}
            className="text"
            placeholder="Instruction text..."
          />
          <ErrorMessage
            name={`instructions.steps[${index}].text`}
            component="span"
            className="error-msg"
          />
          <button
            type="button"
            className="remove"
            onClick={() => arrayHelpers.remove(index)}
          >
            -
          </button>
        </div>
      </Draggable>
    );
  }
}

export default Instruction;
