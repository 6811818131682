import React, { Component } from 'react';
import './Ingredients.css';

interface Props {
  ingredients: any;
}
interface State {
  multiplier: string;
}

class Ingredients extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      multiplier: '1',
    };

    this.updateFactor = this.updateFactor.bind(this);
    this.calculateIngredient = this.calculateIngredient.bind(this);
    this.calculateMultiplier = this.calculateMultiplier.bind(this);
    this.calculateNumerator = this.calculateNumerator.bind(this);
    this.calculateDenominator = this.calculateDenominator.bind(this);
  }

  updateFactor(event: any) {
    this.setState({ multiplier: event.target.value });
  }

  reduceFraction(numerator: number, denominator: number) {
    // Reduce a fraction by finding the Greatest Common Divisor and dividing by it.
    var gcd = function gcd(a: number, b: number) : number {
      return b ? gcd(b, a % b) : a;
    };
    var divisor = gcd(numerator, denominator);
    return [numerator / divisor, denominator / divisor];
  }

  calculateMultiplier(): number {
    // Calculate multiplier for decimal amount
    const mult = this.state.multiplier.split('/');
    if (mult.length === 1) {
      return parseInt(mult[0]);
    } else if (mult.length === 2) {
      return parseInt(mult[0]) / parseInt(mult[1]);
    } else {
      return 1;
    }
  }

  calculateNumerator(num: number) {
    // Calculate numerator with multiplier
    const mult = this.state.multiplier.split('/');
    if (mult.length === 1 || mult.length === 2) {
      return parseInt(mult[0]) * num;
    } else {
      return num;
    }
  }

  calculateDenominator(num: number) {
    // Calculate denominator with multiplier
    const mult = this.state.multiplier.split('/');

    if (mult.length === 2) {
      return parseInt(mult[1]) * num;
    } else {
      return num;
    }
  }

  calculateFraction(num: number, den: number) {
    // Create reduced string representation of fraction
    let whole = Math.floor(num / den);
    num = num % den;

    if (num === 0) {
      return whole;
    } else {
      let reduced = this.reduceFraction(num, den);
      let fraction = `${reduced[0]}/${reduced[1]}`;

      return whole === 0 ? fraction : `${whole} ${fraction}`;
    }
  }

  calculateConversion(num: number, den: number, measure: string): [number, number, string] {
    // Check for conversions to simplify for end user
    let amount = num / den;

    switch (measure) {
      case 'cup':
        if (amount < 0.25) {
          return [num * 16, den, 'tbsp'];
        } else if (amount < 0.05) {
          return [num * 48, den, 'tsp'];
        }
        break;
      case 'tbsp':
        if (amount >= 4 && (amount % 4 === 0)) {
          return [num, den * 16, 'cup'];
        } else if (amount < 0.34) {
          return [num * 3, den, 'tsp'];
        }
        break;
      case 'tsp':
        if (amount >= 3  && (amount % 1.5 === 0)) {
          return [num, den * 3, 'tbsp'];
        } else if (amount >= 24) {
          return [num, den * 48, 'cup'];
        }
        break;
      case 'lb':
        if (amount < 0.25) {
          return [num * 16, den, 'oz'];
        }
        break;
      case 'oz':
        if (amount >= 16 && (amount % 4 === 0)) {
          return [num, den * 16, 'lb'];
        }
        break;
      case 'kg':
        if (amount < 1) {
          return [num * 1000, den, 'g'];
        }
        break;
      case 'g':
        if (amount >= 500 && (amount % 500 === 0)) {
          return [num, den * 1000, 'kg'];
        } else if (amount < 1) {
          return [num * 1000, den, 'mg'];
        }
        break;
      case 'mg':
        if (amount >= 500 && (amount % 500 === 0)) {
          return [num, den * 1000, 'g'];
        }
        break;
      default:
        return [num, den, measure];
    }
    return [num, den, measure];
  }

  calculateIngredient(i: any) {
    // Calculate ingredient with multiplier
    let num = i.numerator;
    let den = i.denominator;

    if (num !== 0 && den !== 0) {
      num = this.calculateNumerator(num);
      den = this.calculateDenominator(den);

      let ing = this.calculateConversion(num, den, i.measurement);

      return this.calculateFraction(ing[0], ing[1]) + ' ' + ing[2];
    }
    return (
      Math.floor(i.amount * this.calculateMultiplier() * 100) / 100 +
      ' ' +
      i.measurement
    );
  }

  render() {
    const ingredients = this.props.ingredients;

    return (
      <div className="Recipe-Ingredients">
        <div className="Recipe-Section-Header">
          {/* <img src="/static/svg/icon-cart.svg" alt="Ingredients Icon" /> */}
          <h2>Ingredients</h2>
        </div>
        Multiplier
        <select
          name="multiplier"
          className="Recipe-Multiplier"
          value={this.state.multiplier}
          onChange={this.updateFactor}
        >
          <option value="10">10</option>
          <option value="8">8</option>
          <option value="6">6</option>
          <option value="4">4</option>
          <option value="3">3</option>
          <option value="2">2</option>
          <option value="1">1</option>
          <option value="3/4">3/4</option>
          <option value="2/3">2/3</option>
          <option value="1/2">1/2</option>
          <option value="1/3">1/3</option>
          <option value="1/4">1/4</option>
          <option value="1/8">1/8</option>
          <option value="1/16">1/16</option>
        </select>
        <ul>
          {ingredients.map((i: any) => (
            <li property="recipeIngredient" key={i.name}>
              <img src="/static/svg/icon-tag.svg" alt="Ingredient" />
              {this.calculateIngredient(i) + ' '}
              {i.name}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default Ingredients;
