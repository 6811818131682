import React, { Component } from 'react';
import { FieldArray } from 'formik';
import Instruction from './Instruction';
import './Instructions.css';

interface Props {
  steps: any,
  setFieldValue: any
}
interface State {}

class Instructions extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    var steps = this.props.steps;

    return (
      <div className="edit-instructions">
        <h4>Instructions</h4>
        <FieldArray
          name="instructions.steps"
          render={(arrayHelpers) => (
            <div>
              <div className="instructions">
                {steps.map((instruction: any, index: number) => (
                  <Instruction
                    index={index}
                    arrayHelpers={arrayHelpers}
                    setFieldValue={this.props.setFieldValue}
                    key={index}
                  />
                ))}
              </div>
              <button
                type="button"
                className="add"
                onClick={() =>
                  arrayHelpers.push({ id: steps.length + 1, text: '' })
                }
              >
                +
              </button>
            </div>
          )}
        />
      </div>
    );
  }
}

export default Instructions;
