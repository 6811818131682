import React, { Component } from 'react';
import { FieldArray, Field, ErrorMessage } from 'formik';
import './Ingredients.css';

interface Props {
  ingredients: any
}

class Ingredients extends Component<Props, {}> {
  render() {
    var ingredients = this.props.ingredients;

    return (
      <div className="edit-ingredients">
        <h4>Ingredients</h4>
        <FieldArray
            name="ingredients"
            render={arrayHelpers => (
              <div>
                {ingredients.map((ingredient: any, index: number) => (
                  <div key={index}>
                    <Field name={`ingredients[${index}].name`} className="name" placeholder="Name" />
                    <ErrorMessage name={`ingredients[${index}].name`} component="span" className="error-msg" />
                    <Field name={`ingredients[${index}].whole`} className="whole" />
                    <ErrorMessage name={`ingredients[${index}].whole`} component="span" className="error-msg" />
                    <div className="fraction">
                      <Field name={`ingredients[${index}].numerator`} className="numerator" />
                      <ErrorMessage name={`ingredients[${index}].numerator`} component="span" className="error-msg" />
                      <Field name={`ingredients[${index}].denominator`} className="denominator" />
                      <ErrorMessage name={`ingredients[${index}].denominator`} component="span" className="error-msg" />
                    </div>
                    <Field name={`ingredients[${index}].measurement`} component="select" className="measurement">
                      <option value=""></option>
                      <option value="cup">cup</option>
                      <option value="tbsp">tbsp</option>
                      <option value="tsp">tsp</option>
                      <option value="lb">lb</option>
                      <option value="oz">oz</option>
                      <option value="kg">kg</option>
                      <option value="g">g</option>
                      <option value="mg">mg</option>
                    </Field>
                    <ErrorMessage name={`ingredients[${index}].measurement`} component="span" className="error-msg" />
                    <button type="button" className="remove" onClick={() => arrayHelpers.remove(index)}>
                      -
                    </button>
                  </div>
                ))}
                <button type="button" className="add" onClick={() => arrayHelpers.push({ name: '', amount: 0, whole: 0, numerator: 0, denominator: 0,  measurement: ''})}>
                  +
                </button>
              </div>
            )}
          />
      </div>
    );
  }
}

export default Ingredients;
