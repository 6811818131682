import React, { Component } from 'react';
import './RecipesMessage.css';

interface Props {
  message: string
}
class RecipesMessage extends Component<Props, {}> {
  render() {
    if(this.props.message !==''){
      return (
        <div className="RecipesMessage">
          {this.props.message}
        </div>
      );
    }else{
      return (null);
    }
  }
}

export default RecipesMessage;
