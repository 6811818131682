import React, { Component } from 'react';
import './Instructions.css';

interface Props {
  instructions: any
}
class Instructions extends Component<Props, {}> {
  render() {
    const instructions = this.props.instructions;

    return (
        <div className="Recipe-Instructions">
          <div className="Recipe-Section-Header">
            {/* <img src="/static/svg/icon-clipboard.svg" alt="Instruction Icon" /> */}
            <h2>Instructions</h2>
        </div>
          <ol property="recipeInstructions">
            {instructions.steps.map((s: { id: React.ReactText; text: React.ReactNode; }) =>
              <li key={s.id}>
                <span>{s.id}</span>
                {s.text}
              </li>
            )}
          </ol>
        </div>
    );
  }
}

export default Instructions;
