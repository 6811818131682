import React, { Component } from 'react';
import './MainLoader.css';

import { DotLoader } from 'react-spinners';

class MainLoader extends Component {
  render() {
      return (
        <div className="Main">
            <div className="MainLoader">
                <DotLoader
                    size={150}
                    color={'#0FBBEC'}
                    loading={true}
                />
            </div>
        </div>
      );
  }
}

export default MainLoader;
