import React, { Component, ErrorInfo } from 'react';
import './CategoryList.css';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

interface Props {
  category: any,
  edit: boolean,
  hide: boolean,
  recipes: any
};
interface State { 
  hidden: boolean,
  hasError: boolean
};

class CategoryList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hidden: true,
      hasError: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event: any) {
    this.setState({ hidden: this.state.hidden ? false : true });
  }

  nameSort(a: {name: string}, b: {name: string}) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    console.log(error.message + info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="CategoryList">
          <h4>Unable to load: {this.props.category}</h4>
        </div>
      );
    }

    const hidden = this.state.hidden && this.props.hide ? true : false;
    const path = this.props.edit ? '/edit/' : '/recipe/';

    let button = '';
    if (this.props.hide === true) {
      button = hidden ? '+' : '-';
    }

    // sort by name
    let recipes = this.props.recipes;
    recipes.sort(this.nameSort);

    return (
      <div className="CategoryList">
        <h4 onClick={this.handleClick}>
          {button} {this.props.category}
        </h4>
        <ul className={hidden ? 'hidden' : 'none'}>
          {recipes.map((recipe: any) => (
            <li key={recipe.id}>
              <Link
                to={path + recipe.id + '/' + recipe.name.split(' ').join('_')}
              >
                {recipe.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  
  static defaultProps = {
    hide: false,
    edit: false,
  };

  static propTypes = {
    hide: PropTypes.bool,
    edit: PropTypes.bool,
    category: PropTypes.string,
    recipes: PropTypes.array,
  };
}

export default CategoryList;
