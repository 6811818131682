import React, { Component } from 'react';

class CommandList extends Component {
  render() {
    return (
      <div>
        <h4>Commands</h4>
        <ul>
          <li>Edit (:e) - enter admin mode</li>
          <li>Expand (:x) - toggle to expand categories</li>
          <li>Help (:h) - where you are now</li>
          <li>Refresh (:r) - reload list of recipes</li>
          <li>Search (:s) - enter search mode</li>
          <li>Broken Image (:b) - toggle broken images</li>
        </ul>
      </div>
    );
  }
}

export default CommandList;
