import React, { Component, ErrorInfo } from 'react';
import { ENDPOINTS } from './../../Config/Config';
import './Recipes.css';

import { DotLoader } from 'react-spinners';

import CategoryList from './../CategoryList/CategoryList';
import CommandList from './CommandList';
import RecipesMessage from './RecipesMessage';

const NEWRECIPE = [
  {
    id: 0,
    name: 'New Recipe',
    recipe_category: 'Admin',
  },
];

interface Props {}
interface State {
  recipes: [any];
  filteredRecipes: [any];
  baseRecipes: any;
  categories: any;
  search: string;
  edit: boolean;
  expand: boolean;
  loading: boolean;
  hasError: boolean;
  message: string;
}

class Recipes extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      recipes: [null],
      filteredRecipes: [null],
      baseRecipes: [],
      categories: [],
      search: '',
      edit: false,
      expand: false,
      loading: true,
      hasError: false,
      message: '',
    };

    this.updateSearch = this.updateSearch.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
    this.filterRecipes = this.filterRecipes.bind(this);
    this.calculateCategories = this.calculateCategories.bind(this);
  }

  updateSearch(event: any) {
    switch (event.target.value.toLowerCase()) {
      case ':e':
        this.setState(
          {
            search: '',
            baseRecipes: NEWRECIPE,
            edit: true,
            message: 'Edit mode enabled',
          },
          this.filterRecipes
        );
        sessionStorage.setItem('mode', 'edit');
        setTimeout(
          () => {
            this.clearMessage();
          },
          750
        );
        break;
      case ':x':
        let _expand = this.state.expand ? false : true;
        this.setState(
          { search: '', expand: _expand, message: `Expand set to ${_expand}` },
          this.filterRecipes
        );
        sessionStorage.setItem('expand', _expand.toString());
        setTimeout(
          () => {
            this.clearMessage();
          },
          750
        );
        break;
      case ':s':
        this.setState(
          {
            search: '',
            baseRecipes: [],
            edit: false,
            message: 'Search mode enabled',
          },
          this.filterRecipes
        );
        sessionStorage.setItem('mode', 'search');
        setTimeout(
          () => {
            this.clearMessage();
          },
          750
        );
        break;
      case ':r':
        this.setState({ search: '', loading: true }, this.loadRecipes);
        break;
      case ':b':
        if (localStorage.getItem('broken-images') === 'none') {
          localStorage.setItem('broken-images', 'display');
          this.setState(
            { search: '', message: 'Displaying broken images' },
            this.filterRecipes
          );
          setTimeout(
            () => {
              this.clearMessage();
            },
            750
          );
        } else {
          localStorage.setItem('broken-images', 'none');
          this.setState(
            { search: '', message: 'Disabled broken images' },
            this.filterRecipes
          );
          setTimeout(
            () => {
              this.clearMessage();
            },
            750
          );
        }
        break;
      default:
        this.setState({ search: event.target.value }, this.filterRecipes);
    }
  }

  clearMessage() {
    this.setState({ message: '' });
  }

  filterRecipes() {
    let _recipes = this.state.baseRecipes;

    switch (this.state.search.toLowerCase()) {
      case '':
        console.debug('No Filter');
        this.setState(
          { filteredRecipes: _recipes.concat(this.state.recipes) },
          this.calculateCategories
        );
        break;
      default:
        console.debug('Filtered by: ' + this.state.search);
        let filtered = this.state.recipes.filter(
          (recipe: any) =>
            recipe.name
              .toLowerCase()
              .includes(this.state.search.toLowerCase()) ||
            recipe.tags.toLowerCase().includes(this.state.search.toLowerCase())
        );
        this.setState(
          { filteredRecipes: _recipes.concat(filtered) },
          this.calculateCategories
        );
    }
  }

  calculateCategories() {
    // Pull out the categories from the filtered recipes
    const recipes = this.state.filteredRecipes;
    let categories = [],
      hash: any = {},
      length = recipes.length;

    // Loop through each item and add to hash - deduplicating
    for (let i = 0; i < length; ++i) {
      hash[recipes[i].recipe_category as string] = recipes[i].recipe_category;
    }

    // Create array from hash
    for (let cntr in hash) {
      categories.push(hash[cntr]);
    }

    this.setState({ categories: categories.sort() });
  }

  loadRecipes() {
    fetch(ENDPOINTS.getRecipes)
      .then((response) => response.json())
      .then((data) =>
        this.setState(
          {
            recipes: data,
            loading: false,
          },
          this.filterRecipes
        )
      );
  }

  componentDidMount() {
    if (sessionStorage.getItem('mode') === 'edit') {
      this.setState({ edit: true, baseRecipes: NEWRECIPE });
    }

    if (sessionStorage.getItem('expand') === 'true') {
      this.setState({ expand: true });
    }

    this.loadRecipes();
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true });
    console.log(error.message + info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="Recipes">
          <h3>Unable to render</h3>
        </div>
      );
    }

    let hide = this.state.search || this.state.expand ? false : true;

    if (this.state.search.toLowerCase() === ':h') {
      return (
        <div className="Recipes">
          <h3>Recipes{this.state.edit ? ' (Edit)' : ''}</h3>
          <input
            type="text"
            name="search"
            className="Recipes-Search"
            value={this.state.search}
            onChange={this.updateSearch}
            placeholder="Search Recipes"
          />
          <CommandList />
        </div>
      );
    } else {
      return (
        <div className="Recipes">
          <h3>Recipes{this.state.edit ? ' (Edit)' : ''}</h3>
          <input
            type="text"
            name="search"
            className="Recipes-Search"
            value={this.state.search}
            onChange={this.updateSearch}
            placeholder="Search Recipes"
          />
          <RecipesMessage message={this.state.message} />
          <div className="Recipes-Loader">
            <DotLoader
              size={50}
              color={'#0FBBEC'}
              loading={this.state.loading}
            />
          </div>
          {this.state.categories.map((category: any) => (
            <CategoryList
              key={category}
              category={category}
              recipes={this.state.filteredRecipes.filter(
                (recipe) => recipe.recipe_category === category
              )}
              hide={hide}
              edit={this.state.edit}
            />
          ))}
        </div>
      );
    }
  }
}

export default Recipes;
