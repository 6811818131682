import auth0 from 'auth0-js';

const location = window.location.port ? `${window.location.protocol}//${window.location.hostname}:${window.location.port}` : `${window.location.protocol}//${window.location.hostname}`;

export default class Auth {
  accessToken = '';
  idToken = '';
  expiresAt = 0;

  auth0 = new auth0.WebAuth({
    domain: 'cooking.auth0.com',
    clientID: '1FcpAM3nMwUOjNioPX4ezy6h649CeXoj',
    redirectUri: `${location}/callback`,
    responseType: 'token id_token',
    scope: 'openid',
    audience: 'https://apibc.azurewebsites.net'
  });

  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.authenticate = this.authenticate.bind(this);

    // Try to load data from Session Storage
    let _accessToken = sessionStorage.getItem('accessToken');
    let _idToken = sessionStorage.getItem('idToken');
    let _expiresAt = sessionStorage.getItem('expiresAt');

    if (_accessToken && _idToken && _expiresAt){
      this.accessToken = _accessToken;
      this.idToken = _idToken;
      this.expiresAt = parseInt(_expiresAt);
    }
  }

  login() {
    this.auth0.authorize();
  }

  pushRedirectUri(redirectUri: string){
    if(redirectUri){
      sessionStorage.setItem('authRedirectUri', redirectUri);
    }
  }

  fetchRedirectUri(){
    let uri = sessionStorage.getItem('authRedirectUri');
    sessionStorage.removeItem('authRedirectUri');
    return uri;
  }

  getAccessToken() {
    return this.accessToken;
  }

  getIdToken() {
    return this.idToken;
  }

  setSession(authResult: {expiresIn: number, accessToken: string, idToken: string}) {
    // Get the time that the access token will expire at
    let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();

    // Update local state
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    // Update Session Storage
    sessionStorage.setItem('isLoggedIn', 'true');
    sessionStorage.setItem('accessToken', this.accessToken);
    sessionStorage.setItem('idToken', this.idToken);
    sessionStorage.setItem('expiresAt', this.expiresAt.toString());
  }

  renewSession() {
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.setSession(authResult);
       } else if (err) {
         this.logout();
         console.log(err);
         this.login();
       }
    });
  }

  logout() {
    // Remove tokens and expiry time
    this.accessToken = '';
    this.idToken = '';
    this.expiresAt = 0;

    // Remove isLoggedIn flag from sessionStorage
    sessionStorage.removeItem('isLoggedIn');
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAt = this.expiresAt;
    return new Date().getTime() < expiresAt;
  }

  authenticate(redirectUri: string) {
    // Set redirect page for after authentication
    this.pushRedirectUri(redirectUri);

    // Check to see if user is loggedIn and renew or login as appropriate
    if(sessionStorage.getItem('isLoggedIn') === 'true'){
      this.renewSession();
    }else{
      this.login();
    }
  }
}