import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import './Header.css';

class Header extends Component {
  render() {
      return (
        <header className="header">
          <div className="header-bar">
              <div className="header-float">
                  <h1><Link to="/">Blissfully Cooking</Link></h1>
                  <span>Healthy and Hearty</span>
              </div>
          </div>
          <div className="header-hr"></div>
        </header>
      );
  }
}

export default Header;
