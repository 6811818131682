import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
  render() {
      return (
        <footer id="footer" className="footer">
          <div className="footer-hr"></div>
          <div className="footer-contact">
            <h2 className="major"><span>Get in touch</span></h2>
            <ul className="footer-contact">
              <li><a href="https://www.facebook.com/BlissfullyCooking" className="facebook">Facebook</a></li>
              <li><a href="https://twitter.com/BlissfullyCook" className="twitter">Twitter</a></li>
              <li><a href="http://blog.blissfullycooking.com/rss/" className="rss">RSS</a></li>
              <li><a href="https://plus.google.com/u/1/116024984279464878816" className="googleplus">Google+</a></li>
              <li><a href="https://instagram.com/blissfullycook/" className="instagram">Instagram</a></li>
              <li><a href="https://www.pinterest.com/BlissfullyCook/" className="pinterest">Pinterest</a></li>
            </ul>
          </div>
          <div id="copyright">
            © 2018 BlissfullyCooking
          </div>
        </footer>
      );
  }
}

export default Footer;
